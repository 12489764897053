//Modules
import { useNavigate } from 'react-router-dom'

//Components
import ListaCursos from './../GuiaECTS/ListaCursos'
import Webdocs from './../WebDocs/Webdocs'
import MaisInfoAcord from './InfoAcord/MaisInfoAcord'
import Horarios from './Horarios'

function SubPageContent({ article, menuLevel, index }) {
    
    let navigate = useNavigate()

    const articleColor = {
        backgroundColor: article.acf.bgColor,
        color: article.acf.fontColor
    }

    return (
        <article id={`${article.slug}_${article.id}`} className={
            ((!article.acf.hasOwnProperty('spaceTop') || (article.acf.hasOwnProperty('spaceTop') && !article.acf.spaceTop))? "" : "pt-5 ") +
            ((!article.acf.hasOwnProperty('spaceBottom') || (article.acf.hasOwnProperty('spaceBottom') && !article.acf.spaceBottom))? "" : "pb-5 ")
            } style={articleColor} data-color={article.acf.bgColor === "#ffffff" ? "false" : "true"} >
                <div className="container page">
                    <div className="row">
                        {article.acf.ListaCursos ? <ListaCursos article={article} /> :                             
                                <>
                                    {!article.acf.fullWidth && 
                                        <div className="col-lg-3">
                                            {menuLevel > 3 && index === 0 && <button onClick={() => navigate(-1)}><span className="material-icons-outlined course-back">arrow_back</span></button>}
                                        </div>}
                                    <div className={article.acf.fullWidth ? "col-md-12" : "col-lg-9"}>
                                        {/* <div className={(article.acf.fullCol || article.acf.fullWidth) ? "content position-relative full-col-width" : "content position-relative partial-col-width"}>   */}
                                        <div className="content position-relative full-col-width">  
                                        {(!article.acf.hasOwnProperty('contentOnTop') || (article.acf.hasOwnProperty('contentOnTop') && article.acf.contentOnTop)) &&
                                        <div dangerouslySetInnerHTML={{__html:article.content.rendered}}></div>}
                                            {article.acf.webDocs !== "" ? 
                                                <Webdocs ramo={article.acf.webDocs} showWebdocsTitle={article.acf.hasOwnProperty('showWebdocsTitle') ? article.acf.showWebdocsTitle : true} initial={true} articleColor={articleColor} /> 
                                                :
                                                (article.acf.hasOwnProperty('infoAcord') && article.acf.infoAcord !== "") ? <MaisInfoAcord categorySlug={article.acf.infoAcord} articleColor={articleColor} /> 
                                                :
                                                (article.acf.hasOwnProperty('infoAcord') && article.acf.enableHorarios) && <Horarios codEscola={article.acf.codEscolaHorarios} />
                                            }
                                        {article.acf.hasOwnProperty('contentOnTop') && !article.acf.contentOnTop &&
                                        <div className='mt-4' dangerouslySetInnerHTML={{__html:article.content.rendered}}></div>}
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
        </article>
    )
}

export default SubPageContent
