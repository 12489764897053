//Modules
import { useState, useEffect, useRef } from 'react'

//Utils
import { injectHorarios } from '../../utils/utilHorarios' 


const Horarios = ({ codEscola }) => {
    const [lang, setLang] = useState('')
    const refLang = useRef()

    const updateUi = () => {
        injectHorarios({
            id: 'horarios',
            codEscola: codEscola,  // se for passado não mostra a combo da escola

            escolaStyle: 'escola',
            cursoStyle: 'curso',
            semanaStyle: 'semana',
            anoStyle: 'ano',
            
            labelStyle: 'label',
            
            buttonDivStyle: 'buttonDiv',
            
            escolaPlaceholder: {'pt': '-- Escolha a Escola --', 'en': '-- Choose the School --'},
            cursoPlaceholder: {'pt': '-- Todos os Cursos --', 'en': '-- All the Courses ---'},
            semanaPlaceholder: {'pt': '-- Semana Atual --', 'en': '-- Current Week --'},
            anoPlaceholder: {'pt': '-- Todos os Anos --', 'en': '-- All Years --'},

            escolaLabel: { 'pt': 'Escola', 'en': 'School' },
            cursoLabel: {'pt': 'Curso', 'en': 'Course'},
            semanaLabel: {'pt': 'Semana', 'en': 'Week'},
            anoLabel: {'pt': 'Ano Curricular', 'en': 'Curricular Year'},
            
            buttonText: {'pt': 'Ver PDF', 'en': 'See PDF'},
            
            itemStyle: 'item'
        }, lang);
    }  

    const handleLangChange = (e) => {
        setLang(e.target.value)      
    }

    useEffect(() => {
        updateUi();
    },[lang])


    return (
        <div id="content">
            <h2>Horários</h2>
            <div className='lang'>
                <select value={lang} ref={refLang} onChange={handleLangChange}>
                    <option value=''>-- Escolha o Idioma/Choose Language --</option>
                    <option value='pt'>Português</option>
                    <option value='en'>English</option>
                </select>                
            </div>
            <div id="horarios"></div>
        </div>
    )
}

export default Horarios
