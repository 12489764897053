//Modules
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'


//Utilities
import { menuContext, mediaContext } from '../utils/UtilContext'
import useFetch from '../utils/useFetch'

//Assets
import logo from '../../img/logo-header.png'
import ipbnews from '../../img/logoIPBNews.png'

//Variables
const { REACT_APP_WP_MENU_PROTPROJ, REACT_APP_CACHE_EP_MENU } = process.env

const Footer = () => {
    const [logoHeader, setLogoHeader] = useState(logo)
    const { navItemsTotal, CACHE_API_URL } = useContext(menuContext)
    const { media } = useContext(mediaContext)
    const { data: protocolosProjetos } = useFetch(`${CACHE_API_URL}${REACT_APP_CACHE_EP_MENU}${REACT_APP_WP_MENU_PROTPROJ}`)
    const abortCont = new AbortController()

    useEffect(() => {
        const logoUrl = media.filter((media) => media.slug === 'logo_header')
        logoUrl.length !== 0 && setLogoHeader(logoUrl[0].source_url)
        
        return () => abortCont.abort()

    }, [])

    

    return (
            <footer>
                <div className="separator"></div>    
                <section className="infoFooter">
                    <div className='container'>
                        {navItemsTotal.menuFooter && navItemsTotal.menuFooter.items.map((item) =>(                        
                            <ul key={item.ID} className="">
                                {item?.child_items && item.child_items.map((child) => (                                                                
                                    <li key={child.ID}>
                                        <div id={`${child.slug}_${child.ID}`} className='renderedTitle' dangerouslySetInnerHTML={{__html:child.title}}></div>
                                        <ul className="">
                                            {child?.child_items && child.child_items.map((subChild) => (
                                                <li key={subChild.ID} className="">
                                                    {subChild.object === "custom" ?
                                                            <a href={subChild.url} target="_blank" rel="noreferrer" id={`${subChild.slug}_${subChild.ID}`}>
                                                                <div className='renderedTitle' dangerouslySetInnerHTML={{__html:subChild.title}}></div>
                                                            </a>
                                                        :
                                                            <Link to={`/${item.slug}/${child.slug}/${subChild.slug}`} id={`${subChild.slug}_${subChild.ID}`}>
                                                                <div className='renderedTitle' dangerouslySetInnerHTML={{__html:subChild.title}}></div>
                                                            </Link>}
                                                </li>
                                            ))}                                                                                        
                                        </ul>
                                    </li>
                                ))}
                                <li className='ipb-news'>
                                    <img src={ipbnews} alt="Logotipo IPB News" />
                                    <a href='https://ipbnews.ipb.pt' target='_blank' rel="noreferrer">Partilha de Notícias e Eventos</a>
                                </li>
                            </ul>                   
                        ))} 
                    </div>
                </section>
                <section className='ContactsFooter'>
                    <div className="container">
                        <div className="footerLogo">
                            <img src={logoHeader} alt="Logo IPB" />
                        </div>   
                        <div className='contacts'>
                            <p>Campus de Santa Apolónia 5300-253 Bragança, Portugal
                            <br />T: (351) 273 303 200 / 273 331 570</p>
                        </div> 
                        <div className='redesSociais'>                                      
                            <ul>
                                <li><a href="https://facebook.com/ipbraganca" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f fa-2x"></i></a></li>
                                <li><a href="https://www.instagram.com/institutopolitecnicobraganca" target="_blank" rel="noreferrer"><i className="fab fa-instagram fa-2x"></i></a></li>
                                <li><a href="https://www.youtube.com/c/InstitutoPolit%C3%A9cnicodeBragan%C3%A7a" target="_blank" rel="noreferrer"><i className="fab fa-youtube fa-2x"></i></a></li>
                            </ul>
                        </div>                                          
                    </div>
                </section>
                <section className="protocolosProjetos">
                    <ul>
                        {protocolosProjetos ?
                            protocolosProjetos.items.map((protProj) => (
                                <li key={protProj.ID}><a href={protProj.url} target="_blank" rel='noreferrer'><img src={protProj.acf.imagem} title={protProj.post_title} alt={protProj.post_title} /></a></li>
                            ))
                            : <Icon icon="svg-spinners:eclipse" />
                        }
                    </ul>
                </section>
            </footer>
            )
}

export default Footer
