
const $ = window.$
let ServerUrl = process.env.REACT_APP_MINI_URL_SERVER



const showForm = (hash, url) => {
    let mask = $('<div class="mask"></div>');
    mask.hide();
    mask.css('z-index', '10000');
    mask.css("position", "absolute");
    mask.css("background-color", "#555");
    mask.css("width", $(window).width() + "px");
    mask.css("height", $(window).height() + "px");
    mask.css("top", "0px");
    mask.css("left", "0px");
    $('body').append(mask);
    let div = $('<div class="ipb-url-dialog"></div>');
    div.css('width', '312px');
    div.hide();
    $('body').append(div);

    div.append($('<div>Pode usar a seguinte Mini-URL como alternativa\npara aceder à página actual:</div>'));
    div.css('z-index', '10001');

    let anchor = $('<a href="' + hash + '">' + hash + '</a>');
    anchor.css('width', '100%');
    div.append($("<div>» </div>").append(anchor));

    let more = $('<div></div>');
    more.hide();

    let input = $('<input type="text" value="' + url + '"></input>');
    input.css('width', '100%');
    more.append('<hr/>');
    more.append(input);

    let again = $('<a href="javascript:">Actualizar</a>');
    again.click(function () {
        generate(input.val(), function (data) {
            if (data.error !== undefined) {
                alert('Erro:\n' + data.error);
            } else {
                anchor.attr('href', data.url);
                anchor.text(data.url);
            }
        });
    });

    more.append($('<div class="buttons"></div>').append(again));

    div.append(more);

    let change = $('<a href="javascript:">Alterar URL</a>');
    let close = $('<a href="javascript:">Fechar</a>');

    div.append('<hr/>');
    div.append($('<div class="buttons"></div>').append(change).append("<span>|</span>").append(close));

    change.click(function () {
        more.toggle('fast');
    });

    close.click(function () {
        div.fadeOut('fast', function () {
            mask.fadeOut('fast');
        });
    });

    div.css("position", "absolute");
    div.css("top", ($(window).height() - div.height()) / 2 + $(window).scrollTop() + "px");
    div.css("left", ($(window).width() - div.width()) / 2 + $(window).scrollLeft() + "px");

    mask.fadeTo('fast', 0.5, function () {
        div.fadeIn('fast', function () {
            close.focus();
        });
    });

};

const generate = (url, done) => {
    $.getJSON(
        ServerUrl + 'create?callback=?',
        {url: url},
        function (data) {
            done(data);
        }
    );
};

export const create = (ref) => {    
    let url = window.location.href;
    
    generate(url, function (data) {
        if (data.error !== undefined) {
            alert('Erro:\n' + data.error);
        } else {
            showForm(data.url, url);
        }
    });
};