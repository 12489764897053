//Modules
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

const useFetch = (url) => {
    const [loading, setLoading] = useState(true)
    const { data, isError } = useQuery(
        [url], 
        async () => {
            const res = await fetch(url)
            const resData = await res.json()            
            setLoading(false)
            return resData
        }, 
        {
            initialData: null
        })

    return { data, loading, isError }
}

export default useFetch