//Modules
import { useContext } from 'react'

//Utils
import { menuContext } from '../utils/UtilContext'

const useReturnMenuLevel = (menu) => {
    const { navItemsTotal } = useContext(menuContext)

    let menuLevelCount = 0
    let menuLevel = 0

    function findMenu(menus, cond){
        menuLevelCount ++
        menus.map((menu) => {
            menu.ID === cond.ID ?
            menuLevel = menuLevelCount
            :
            menu?.child_items && findMenu(menu.child_items, cond)
            return true
        })
        menuLevelCount --
    }

    findMenu(navItemsTotal.navItems.items, menu)
  
    return menuLevel
}

export default useReturnMenuLevel