// Modules
import { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Icon } from '@iconify/react'

//Utilities
import { createRoutes } from './components/utils/utilFunctions'
import { menuContext, NewsContextProvider, EventsContextProvider } from './components/utils/UtilContext'

//Components
import Home from './components/pages/Home'
import Page from './components/pages/Page'
import Noticias from './components/pages/Noticias'
import NoticiaDetalhe from './components/pages/NoticiaDetalhe'
import Eventos from './components/pages/Eventos'
import Footer from './components/widgets/Footer'
import EventoDetalhe from './components/pages/EventoDetalhe'
import NotFound from './components/pages/NotFound'
import SearchForm from './components/widgets/Search/SearchForm'
import NavMobile from './components/widgets/Nav/NavMobile'
import MiniNav from './components/widgets/Nav/MiniNav'
import ToTop from './components/widgets/ToTop'

function App() {
  const { loading, navItemsTotal } = useContext(menuContext)
  let menuRoutes = []

  if (loading) {
    return <Icon icon="svg-spinners:ring-resize" />        
  }else{
    menuRoutes = createRoutes(navItemsTotal)

    return (
      <div>        
        <SearchForm />
        <div className='pageOverlay'></div>
        <NavMobile />
        <MiniNav />
        {navItemsTotal &&
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/noticias'>             
              <Route index element={<NewsContextProvider><Noticias /></NewsContextProvider>} />                       
              <Route path=":slugNews" element={<NoticiaDetalhe />} />
            </Route>
            <Route path='/eventos'>
              <Route index element={<EventsContextProvider><Eventos /></EventsContextProvider>} />
              <Route path=":slugEvento" element={<EventoDetalhe />} />
            </Route>          
            {menuRoutes.map((menu, index) => (
              <Route key={index} path={menu.urlRoute}>
                <Route index element={<Page menu={menu} />} />
                {menu.urlRoute.includes("/estudar/cursos") && 
                  <Route path=':slugCurso' element={<Page menu={menu} />} />}              
              </Route>
            ))}
            <Route path="*" element={<NotFound />} />    
        </Routes>}
        <ToTop />  
        <Footer />
      </div>
    )
  }
}

export default App;