import React, { Fragment } from 'react'

import useFetchXML from '../../utils/useFetchXML';
import ListDocs from './widgets/ListDocs';

const { REACT_APP_WEBDOCS, REACT_APP_WEBDOCS_PT } = process.env

function Webdocs({ ramo, showWebdocsTitle, initial, articleColor }) {
    const { data: docs } = useFetchXML(`${REACT_APP_WEBDOCS}?entityId=${parseInt(ramo)}&start=${ramo}${REACT_APP_WEBDOCS_PT}`)    

  return (
    <>
       { docs &&
        <>
            <div className="accordion webdocs-accordion" id={"webdocs-accordion_"+docs.entity.path[0].entity[0].$.id}>
                <div className="card webdocs-card">
                    <div className="card-header" id={"ramo_"+docs.entity.path[0].entity[0].$.id} style={articleColor}>                        
                        {initial ?                        
                            showWebdocsTitle && 
                                <div className='webdocs-title'>
                                    <strong className="color-primary">{docs.entity.$.name}</strong>
                                </div>                                                        
                        :
                        (!docs.entity.docs[0]?.doc && !docs.entity.children[0]?.entity) ? "" :                     
                            <button className="btn webdocs-title collapsed" type="button" data-toggle={(!docs.entity.docs[0]?.doc && !docs.entity.children[0]?.entity) ? "" : "collapse"} data-target={"#collapse_"+docs.entity.path[0].entity[0].$.id} aria-expanded="false" aria-controls={"collapse_"+docs.entity.path[0].entity[0].$.id}>
                                <strong className="">{docs.entity.$.name}</strong>
                                <span className="buttonWebdocs"></span>
                            </button>
                        }
                    </div> 
                    <div id={"collapse_"+docs.entity.path[0].entity[0].$.id} className={initial ? "collapse show" : "collapse"} aria-labelledby={"ramo_"+docs.entity.path[0].entity[0].$.id} data-parent={"#webdocs-accordion_"+docs.entity.path[0].entity[0].$.id}>
                        <div className="card-body" style={articleColor}>                            
                            {docs.entity.docs[0]?.doc && <ListDocs docs={docs.entity.docs[0].doc} />}            
                            {(docs.entity.children[0].entity ?? []).map((ent) => (
                                <Fragment key={ent.$.id}>        
                                    <Webdocs ramo={ent.$.id} showWebdocsTitle={true} initial={false} articleColor={articleColor} />
                                </Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </> } 
    </>
  )
}

export default Webdocs