import React, { Fragment } from 'react'

function PlanoEstudos({ plano, index }) {
    
  return (
    <article className='my-5'>
        <div className="container page">
            <div className="row">
                <div className="col-md-12">
                    <p className='course-title-detail'><strong>Plano de Estudos {index !== 0 && "(NOVO)"}</strong></p>
                    <table className='plano-estudos-detail'>
                        <tbody>
                            <tr>
                                <th><strong>Código</strong></th>
                                <th><strong>Unidade Curricular</strong></th>
                                <th><strong>Ano</strong></th>
                                <th><strong>Tipo</strong></th>
                                <th><strong>Área Científica</strong></th>
                                <th><strong>ECTS</strong></th>
                            </tr>
                            {plano.map((uc) => (  
                                <Fragment key={uc.$.n_disciplina}>                              
                                    {uc.$.opcao === "false" ?
                                        <tr>
                                            <td>{uc.$.codigo}</td>
                                            <td><a href={uc.$.url} target="_blank" rel="noreferrer">{uc.$.nome}</a></td>
                                            <td>{uc.$.ano}</td>
                                            <td>{"S"+uc.$.semestre}</td>
                                            <td>{uc?.area ? uc.area[0].$.nome : ""}</td>
                                            <td>{uc.$.ects}</td>
                                        </tr>
                                        :  
                                        <tr>
                                            <td colSpan={6} className='opcao'>
                                                <div className="accordion plano opcao">
                                                    <div className="card cardAccordion">
                                                        <div className="card-header" id={uc.$.nome.replace(" ", "-")}>
                                                            <button className="btn collapsed" type="button" data-toggle="collapse" data-target={`#collapse-${uc.$.nome.replace(" ", "-")}`} aria-expanded="false" aria-controls={`collapse-${uc.$.nome.replace(" ", "-")}`}>
                                                                <strong>{uc.$.nome}</strong>
                                                                <span></span>
                                                            </button>
                                                        </div>

                                                        <div id={`collapse-${uc.$.nome.replace(" ", "-")}`} className="collapse" aria-labelledby={uc.$.nome.replace(" ", "-")} >
                                                            <div className="card-body">
                                                                <table>
                                                                    <tbody>
                                                                        {uc.opcao.map((op, index) => (                                                                        
                                                                            <tr key={op.$.codigo} className={index === uc.opcao.length - 1 ? "no-border" : ""}> 
                                                                                <td width="20%">{op.$.codigo}</td>
                                                                                <td width="47.7%"><a href={op.$.url} target="_blank" rel="noreferrer">{op.$.nome}</a></td>
                                                                                <td width="5.4%">{uc.$.ano}</td>
                                                                                <td width="5.89%">{"S"+uc.$.semestre}</td>
                                                                                <td width="14.6%">{uc?.area ? uc.area[0].$.nome : ""}</td>
                                                                                <td>{uc.$.ects}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>                                        
                                        </tr>                                    
                                    }
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>












    </article>
  )
}

export default PlanoEstudos