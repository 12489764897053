//Modules
import { useState, useEffect } from "react";
import parser from "xml2js";

const useFetchXML = (url) => {
    const [data, setData] = useState(null)
    const abortCont = new AbortController()

    useEffect(() => {
        const getDataItems = async () => {
            const res = await fetch(url, { signal: abortCont.signal })            
            const xml = await res.text()
            parser.parseString(xml, (err, dataItems) => {
                if (err){
                    console.log(err)
                    return
                }
                setData(dataItems)
            })
        }
        getDataItems()

        return () => abortCont.abort()
    
    },[url])

    return { data }
}

export default useFetchXML