import React from 'react'

function FiltroData({anos, filtroAno, filtroMes, handleChange, setfiltroAno, setfiltroMes }) {
    

    return (
      <div className="filtro">
        <div className="meses">
          <ul value="0" className="mesesList" onClick={handleChange}>
            <li id="0" className="selected">
              Todos
            </li>
            <li id="1">
              jan
            </li>
            <li id="2">
              fev
            </li>
            <li id="3">
              mar
            </li>
            <li id="4">
              abr
            </li>
            <li id="5">
              mai
            </li>
            <li id="6">
              jun
            </li>
            <li id="7">
              jul
            </li>
            <li id="8">
              ago
            </li>
            <li id="9">
              set
            </li>
            <li id="10">
              out
            </li>
            <li id="11">
              nov
            </li>
            <li id="12">
              dez
            </li>
          </ul>
        </div>
        <div className="mesesSel">
          <select
            name="Mes"
            value={filtroMes}
            onChange={(ev) => setfiltroMes(ev.target.value)}
          >
            <option value="0">Mês...</option>
            <option value="1">Janeiro</option>
            <option value="2">Fevereiro</option>
            <option value="3">Março</option>
            <option value="4">Abril</option>
            <option value="5">Maio</option>
            <option value="6">Junho</option>
            <option value="7">Julho</option>
            <option value="8">Agosto</option>
            <option value="9">Setembro</option>
            <option value="10">Outubro</option>
            <option value="11">Novembro</option>
            <option value="12">Dezembro</option>
          </select>
        </div>
        <div className="select">
          <select
            name="Ano"
            value={filtroAno}
            onChange={(ev) => setfiltroAno(ev.target.value)}
          >
            <option value="0">Ano...</option>
            {anos.map((ano, index) => (
              <option key={index} value={ano}>
                {ano}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
}

export default FiltroData
