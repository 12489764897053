import React from 'react'

function TopSearch() {
    return (
        <div className='searchButton'>
            <button type='button' className="btn-top search" data-toggle="modal" data-target="#searchModal"><span className="material-icons">search</span></button>
        </div>
    )
}

export default TopSearch
