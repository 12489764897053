import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as urlSlug from 'url-slug'

const { REACT_APP_IPBNEWS_EP, REACT_APP_IPBNEWS_NEWS } = process.env

function SearchNews({ filtroPesquisa }) {
    const [news, setNews] = useState("")

    const abortCont = new AbortController()

    const $ = window.$
    let navigate = useNavigate();

    const goToLink = (ev, to) => {
        ev.preventDefault();
        $('#searchModal').modal('hide')
        navigate(`/noticias/${to}`)
    }

    useEffect(() => {
        const getData = async () => {     
            let resContent = await fetch(`${REACT_APP_IPBNEWS_EP}${REACT_APP_IPBNEWS_NEWS}`, { signal: abortCont.signal })            
            const dataContent = await resContent.json()
            setNews(dataContent)    
        }
        filtroPesquisa !== "" && getData() 
        
        return () => abortCont.abort()
    }, [filtroPesquisa])

    let newsSearch = news &&
        news.filter((newsItem) => {
            return newsItem.titulo.includes(filtroPesquisa) || (newsItem?.categoria && newsItem.categoria.includes(filtroPesquisa)) || (newsItem?.estrutura && newsItem.estrutura.includes(filtroPesquisa))
        })

    return (
        <> 
            {news !== null && filtroPesquisa !== "" && newsSearch.length !== 0 &&
                <div className='noticiasRes'>
                    <span className='titulo'>Notícias</span>
                    <ul>                                        
                        {newsSearch.map((newsItem) => (
                            <li key={newsItem.press_noticia_id}>
                                <button onClick={(event) => goToLink(event, urlSlug.convert(`${newsItem.titulo} ${newsItem.press_noticia_id}`))}>{newsItem.titulo}</button>
                                <p>{newsItem.resumo.substring(0,130)}...</p>
                            </li>
                    ))}
                    </ul>
                </div>
            }
        </>
    )
}

export default SearchNews
