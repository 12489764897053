//Modules
import { useContext } from 'react'
import Carousel from 'react-bootstrap/Carousel'

//Utils
import useFetch from '../utils/useFetch'
import { menuContext } from '../utils/UtilContext'

const { REACT_APP_CACHE_EP_MENU, REACT_APP_WP_MENU_SLIDER } = process.env

const FeaturedSlider = () => {
    const { CACHE_API_URL } = useContext(menuContext)

    const { data: navSlider } = useFetch(`${CACHE_API_URL}${REACT_APP_CACHE_EP_MENU}${REACT_APP_WP_MENU_SLIDER}`)

    if (!navSlider) {return (<></>)}

    const { items } = navSlider

    return (
        <>
            <Carousel interval={7000} pause={false} nextIcon={<span aria-hidden="true" className="" />} prevIcon={<span aria-hidden="true" className="" />} id="homeSlider">
                {items.map((item) => (                    
                    <Carousel.Item key={item.ID}> 
                        <a href={item.url} target="_blank" rel="noreferrer">
                            <div className="carouselImg" style={{backgroundImage: `url(${item.acf.imagem})`}}></div>                      
                            {(item.title !== "." && item.acf.texto !== ".") &&
                                <Carousel.Caption>
                                    <div className="container">
                                        <h3>{item.title > 60 ? `${item.title.substring(0,60)}...` : item.title}</h3>
                                        <p>{item.acf.texto.length > 130 ? `${item.acf.texto.substring(0,130)}...` : item.acf.texto}</p>
                                    </div>
                                </Carousel.Caption>}
                        </a>
                    </Carousel.Item>                          
                ))}                
            </Carousel>
        </>

    )
}

export default FeaturedSlider
