import React from 'react'

function ListDocs({ docs }) {
    
  return (
    <div className='webdocs-docs'>
        {(docs ?? []).map((doc, index) => (
            <span key={index}>
                <a href={doc.$.url} target='_blank' rel='noreferrer'>{doc.$.text}</a>
                <p>{doc._}</p>
            </span>
        ))}
    </div>
  )
}

export default ListDocs