//Modules
import { useEffect, useState, Fragment } from 'react'

//Components
import Webdocs from '../../WebDocs/Webdocs'

function MaisInfoAcordItem({ item, index, articleColor }) {
  const [randomID, setRandomId] = useState()
  const delimiterPattern = /\{\{.*?\}\}/
  let foundPattern = []
  let regex = new RegExp(delimiterPattern, "g");

  useEffect(() => {
    setRandomId(Math.floor(Math.random() * 100000))
  } , [])


item.content.rendered.split(" ").map(item => {
  if (item.match(delimiterPattern)) {
    foundPattern.push(item.replace('{{','').replace('}}',''))
    return null
  }
  return item
}).filter(Boolean)

const parsedContent = item.content.rendered.split(regex)


  return (
    <>
    {randomID &&
      <div className="card cardAccordion">
          <div className="card-header" style={articleColor} id={`heading_${index}_${item.id}_${randomID}`}>
              <button className="btn collapsed" type="button" data-toggle="collapse" data-target={`#collapse_${index}_${item.id}_${randomID}`} aria-expanded="false" aria-controls={`collapse_${index}_${item.id}_${randomID}`}>
                  <strong>{item.title.rendered}</strong>
                  <span></span>                                   
              </button>
          </div>

          <div id={`collapse_${index}_${item.id}_${randomID}`} className="collapse" aria-labelledby={`heading_${index}_${item.id}_${randomID}`} >
              <div className="card-body" style={articleColor}>                            
                <div>
                  {parsedContent.map((text, index) => {
                      if (parsedContent.length !== index+1){
                        if (parseInt(foundPattern[index])){
                          return <Fragment key={`${item.id}_${index}`}>
                            <div dangerouslySetInnerHTML={{__html:text}}></div>
                            <Webdocs ramo={parseInt(foundPattern[index])} showWebdocsTitle={item.acf.hasOwnProperty('showWebdocsTitle') ? item.acf.showWebdocsTitle : true} initial={true} articleColor={articleColor} />                            
                          </Fragment>                          
                        }else{
                          return <Fragment key={`${item.id}_${index}`}>
                            <div dangerouslySetInnerHTML={{__html:text}}></div>
                          </Fragment>                          
                        }
                      }else{                        
                        return <Fragment key={`${item.id}_${index}`}>
                            {text.length > 0 && <div dangerouslySetInnerHTML={{__html:text}}></div>}
                          </Fragment>
                      }
                  })}
                </div>
              
              </div>
          </div>
      </div>
    }
    </>
  )
}

export default MaisInfoAcordItem