//Modules
import { useState, useEffect, useContext } from 'react'

//Utils
import MaisInfoAcordItem from './MaisInfoAcordItem'
import { menuContext } from '../../../utils/UtilContext'

const { REACT_APP_WP_EP, REACT_APP_WP_EP_SEARCH_POSTS_CATEGORY, REACT_APP_WP_EP_SEARCH_CATEGORIES_SLUG } = process.env

function MaisInfoAcord({ categorySlug, articleColor }) {
    const { API_URL } = useContext(menuContext)
    const [contentsCategory, setContentsCategory] = useState([])
    const abortCont = new AbortController()

    useEffect(() => {
        const getCategory = async () => {
            const resCategory = await fetch(`${API_URL}${REACT_APP_WP_EP}${REACT_APP_WP_EP_SEARCH_CATEGORIES_SLUG}${categorySlug}`, { signal: abortCont.signal })
            const dataCategory = await resCategory.json()
            const resContentPosts = await fetch(`${API_URL}${REACT_APP_WP_EP}${REACT_APP_WP_EP_SEARCH_POSTS_CATEGORY}${dataCategory[0].id}&per_page=100`, { signal: abortCont.signal })
            const dataContentPosts = await resContentPosts.json()
            setContentsCategory(dataContentPosts)
        }
        getCategory()        
    }, [categorySlug]) 

    contentsCategory.length > 0 && 
    contentsCategory.sort((a, b) => {
        if (a.acf?.ordem && b.acf?.ordem){            
            const orderA = parseInt(a.acf.ordem)
            const orderB = parseInt(b.acf.ordem)
    
            if (orderA < orderB) {
                return -1
            }
            if (orderA > orderB) {
                return 1
            }            
        }
        const dateA = new Date(a.date)
        const dateB = new Date(b.date)

        return dateB - dateA
    })

  return (
    <>
        { contentsCategory ?
        <>
            <div className="accordion maisInfo">
                {contentsCategory.map((item, index) => (
                    <MaisInfoAcordItem key={item.id} item={item} index={index} articleColor={articleColor} />
                ))}
            </div>
        </>
        : null
        }
    </>
  )
}

export default MaisInfoAcord