import React from 'react'

import TopApps from './TopApps'
import TopSearch from './TopSearch'
import SobreIPB from './SobreIPB'
import TopMiniUrl from './TopMiniUrl'



function MiniNav() {
  return (
    <div className='miniNav'>
        <div className='container'>
            <div className='sobre-ipb' >
              <SobreIPB />
            </div>
            <div className='topButtons'>
                <TopMiniUrl />
                <div className='spacer'></div>
                <TopApps/>
                <div className='spacer'></div>
                <TopSearch />
            </div> 
        </div>
    </div>
  )
}

export default MiniNav