//Modules
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

//Components
import App from './App';

//Utils
import { MenuContextProvider, MediaContextProvider } from './components/utils/UtilContext';

//Assets
import './styles/timeline.min.css'
import './styles/timelineAdapt.css'
import './styles/main.css'

const queryClient = new QueryClient()

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter> 
      <MenuContextProvider>
        <MediaContextProvider>
          <App />
        </MediaContextProvider>          
      </MenuContextProvider>
    </BrowserRouter>
  </QueryClientProvider>
);