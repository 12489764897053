//Modules
import { useContext, useEffect, useState } from 'react'
import { useNavigate} from 'react-router-dom'

//Utils
import useMenuLevels from '../../utils/useMenuLevels'
import { menuContext } from '../../utils/UtilContext'

const { REACT_APP_WP_EP, REACT_APP_WP_EP_SEARCH_PAGES, REACT_APP_WP_EP_SEARCH_POSTS } = process.env

function SearchSite({ filtroPesquisa }) {
    const { navItemsTotal, API_URL } = useContext(menuContext)

    const [pesquisaSitePages, setPesquisaSitePages] = useState([])
    const [pesquisaSitePosts, setPesquisaSitePosts] = useState([])
    
    const { menuLevels: navItemsLevels } = useMenuLevels(navItemsTotal.navItems.items)
    const { menuLevels: sobreIPBLevels } = useMenuLevels(navItemsTotal.sobreIPB.items)
    const { menuLevels: menuFooterLevels } = useMenuLevels(navItemsTotal.menuFooter.items)
    
    let menuLevels = {
        firstLevelMenu: [],
        secondLevelMenu: [],
        thirdLevelMenu: []
    };
   
   Object.keys(navItemsLevels).forEach(key => {
    menuLevels[key] = navItemsLevels[key].concat(sobreIPBLevels[key]).concat(menuFooterLevels[key]);
   });
    
    let filterPageMenu = []
    let filterPostMenu = []

    const abortContPage = new AbortController()
    const abortContPost = new AbortController()

    const $ = window.$
    let navigate = useNavigate();

    const goToLink = (ev, to) => {
        ev.preventDefault();
        let url = to.slug        
        menuLevels.secondLevelMenu.map((Smenu) => {
            if (Smenu.ID === parseInt(to.menu_item_parent)){
                url = `${Smenu.slug}/${url}`
                menuLevels.firstLevelMenu.map((Fmenu) => {
                    if(Fmenu.ID === parseInt(Smenu.menu_item_parent)){
                        url = `/${Fmenu.slug}/${url}`
                    }
                    return null
                })                
            }
            return null
        })
        $('#searchModal').modal('hide')
        navigate(url)
    }

    useEffect(() => {
        const getData = async () => {  
            let resContentPages = await fetch(`${API_URL}${REACT_APP_WP_EP}${REACT_APP_WP_EP_SEARCH_PAGES}"${filtroPesquisa}"`, { signal: abortContPage.signal })            
            const dataContentPages = await resContentPages.json()
            setPesquisaSitePages(dataContentPages)  
        }
        filtroPesquisa !== "" && getData()   
        
        return () => abortContPage.abort()
    }, [filtroPesquisa])

    useEffect(() => {
        const getData = async () => {     
            let resContentPosts = await fetch(`${API_URL}${REACT_APP_WP_EP}${REACT_APP_WP_EP_SEARCH_POSTS}"${filtroPesquisa}"`, { signal: abortContPost.signal })            
            const dataContentPosts = await resContentPosts.json()
            setPesquisaSitePosts(dataContentPosts)  
        }
        filtroPesquisa !== "" && getData()   
        
        return () => abortContPost.abort()
    }, [filtroPesquisa])

    filtroPesquisa !== "" &&
        pesquisaSitePages.map((page) => {
            menuLevels.thirdLevelMenu.map((item) => {
                (item.object === "page" && parseInt(item.object_id) === page.id) && filterPageMenu.push(item)
                return null
            })
            return null
        }) &&
        pesquisaSitePosts.map((post) => {
            menuLevels.thirdLevelMenu.map((item) => {
                (item.object === "category" && parseInt(item.object_id) === post.categories[0] && !filterPostMenu.includes(item)) && filterPostMenu.push(item)
                return null
            })
            return null
        })

    return (
        <>
        {(filterPageMenu.length > 0 || filterPostMenu.length > 0) && <span className='titulo'>Site</span>}
        <div className='siteRes'>                                    
            {filtroPesquisa !== "" && 
            <>
                <ul>                                                                                       
                    {filterPageMenu.map((siteItem) => (                                                    
                        <li key={siteItem.ID}>                     
                            <button onClick={(event) => goToLink(event, siteItem)}>{siteItem.title}</button>
                            <div dangerouslySetInnerHTML={{__html:pesquisaSitePages.find(function (page) {
                                                            if (page.id === parseInt(siteItem.object_id)) return page
                                                            return null
                                                        }).excerpt.rendered.substring(0,100).concat("...")}}>                                                                    
                            </div>
                        </li>
                    ))}
                </ul>           
                <ul>                                        
                    {filterPostMenu.map((siteItem) => (
                        <li key={siteItem.ID}>
                            <button onClick={(event) => goToLink(event, siteItem)}>{siteItem.title}</button>  
                            <div dangerouslySetInnerHTML={{__html:pesquisaSitePosts.find(function (post) {
                                                            if (post.categories[0] === parseInt(siteItem.object_id)) return post
                                                            return null
                                                        }).excerpt.rendered.substring(0,100).concat("...")}}>                                                                    
                            </div>
                        </li>
                    ))}
                </ul>
            </>}
        </div>
        </>
    )
}

export default SearchSite
