//Modules
import { useEffect, useState, useContext } from 'react'

//Utils
import { menuContext } from '../../utils/UtilContext'

const { REACT_APP_WP_EP_PEOPLE } = process.env

function SearchPessoas({ filtroPesquisa }) {
    const { API_URL } = useContext(menuContext)
    const [pesquisaPessoa, setPesquisaPessoa] = useState("")

    const abortCont = new AbortController()

    useEffect(() => {
        const getData = async () => {     
            let resContent = await fetch(`${API_URL}${REACT_APP_WP_EP_PEOPLE}q=${filtroPesquisa}`, { signal: abortCont.signal })            
            const dataContent = await resContent.json()
            setPesquisaPessoa(dataContent)  
        }
        filtroPesquisa !== " " && getData()

        return () => abortCont.abort()
    }, [filtroPesquisa])
    
    return (
        <>
            {pesquisaPessoa !== null && !pesquisaPessoa?.data && pesquisaPessoa.length !== 0 &&
                <div className='pessoasRes'>  
                    <span className='titulo'>Pessoas</span>
                    <ul>
                        {pesquisaPessoa.map((pessoa, index) => (
                                index <= 5 && pessoa.estado === "Activo" && 
                                <li key={pessoa.id}>
                                    <b>{pessoa.nome}</b> {pessoa.tipo ? "-" : ""} <span className='note'>{pessoa.tipo}</span> {pessoa.servico && <span className='servico'>({pessoa.servico})</span>} 
                                    <p>{pessoa.email} | <b>ext.</b> {pessoa.extensao} | <b>gab.</b> {pessoa.porta}</p>
                                </li>
                        ))}
                    </ul>
                </div>
                }
        </>
    )
}

export default SearchPessoas
