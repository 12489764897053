//Modules
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//Components
import Nav from '../widgets/Nav/Nav'

function NotFound() {
    const navigate = useNavigate()

    useEffect(()=>{
        setTimeout(()=>{
            navigate("/")
        }, 4000)
    },[navigate])

    return(
        <>
        <div className="container-fluid sticky-top" style={{backgroundColor: "white", zIndex: "9999"}}>
            <Nav dest={false} content={false} subItem={false}/>
        </div>
        <div style={{textAlign: 'center', marginTop: '80px', marginBottom: '80px'}} className="container-fluid">
            <img src="https://media1.tenor.com/images/9debdd22dd9e43074b7efe9f3a59c570/tenor.gif?itemid=4923610" alt="Nothing To See Here Explosion GIF - NothingToSeeHere Explosion Explode GIFs" width="833" height="555.3333333333334" />
        </div>                                   
        </>
    )
}

export default NotFound
