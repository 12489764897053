//Modules
import { useState, useEffect, Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as urlSlug from 'url-slug'

//Components
import ListaUCs from './ListaUCs'

const { REACT_APP_GUIAECTS_EXTRA_CURRICULAR_MICROCREDENCIAIS } = process.env

function ListaIndividual({ cursos, tipo }) {
    const [lista, setLista] = useState([])
    const [listaExtra, setListaExtra] = useState([])
    const [query, setQuery] = useState("")

    const location = useLocation()

    const { listaCursos, cursosIngles, extraCurr } = cursos

    const escolas = []
    escolas["3040"] = "Instituto Politécnico de Bragança"
    escolas["3041"] = "Escola Superior Agrária"
    escolas["3042"] = "Escola Superior de Educação"
    escolas["3043"] = "Escola Superior de Tecnologia e Gestão"
    escolas["3045"] = "Escola Superior de Comunicação, Administração e Turismo"
    escolas["3046"] = "Escola Superior de Hotelaria e Bem-Estar"
    escolas["7015"] = "Escola Superior de Saúde"

    const campus = []
    campus["3040"] = "Santa Apolónia - Bragança"
    campus["3041"] = "Santa Apolónia - Bragança"
    campus["3042"] = "Santa Apolónia - Bragança"
    campus["3043"] = "Santa Apolónia - Bragança"
    campus["3045"] = "Cruzeiro - Mirandela"
    campus["3046"] = "Campus da Água - Chaves"
    campus["7015"] = "Santa Apolónia - Bragança"

    const filteredCurso = lista.filter(item => {
        return item.$.nome.toLowerCase().includes(query.toLowerCase())
    })

    useEffect(() => {
        let listTipo = []
        let listTipoExtra = []
        
        // if (tipo[0] === "CL"){
        //     cursosLinguas && cursosLinguas.items.map((cl) => {
        //         listaCursos && listaCursos.ipb.curso.map((curso) => {                                         
        //             if (curso.$.cod_curso === cl.acf.cod_curso) {
        //                 listTipo.push(curso)
        //             }
        //             return true
        //         })
        //         return true
        //     })
        // }else 
        if (tipo[0] === "CI"){
            cursosIngles && cursosIngles.items.map((ci) => {
                listaCursos && listaCursos.ipb.curso.map((curso) => {                                         
                    if (curso.$.cod_curso === ci.acf.cod_curso) {
                        listTipo.push(curso)
                    }
                    return true
                })
                return true
            })            
        }else if (tipo[0] === "MC"){
            extraCurr && cursos.extraCurr.extraCurriculares.uc.map((ec) => {                                                       
                if (ec.$.tipo === REACT_APP_GUIAECTS_EXTRA_CURRICULAR_MICROCREDENCIAIS) {
                    listTipoExtra.push(ec.$)
                }
                return true                
            })            
        }else{
            listaCursos ? listTipo = listaCursos.ipb.curso.filter((curso) => {
                if (curso.$.tipo === tipo[0] || curso.$.tipo === tipo[1]) {
                    return curso
                }else{
                    return false
                }
            }) :
            listTipo = []                          
        }
        setLista(listTipo)  
        setListaExtra(listTipoExtra)          
    }, [listaCursos, tipo, cursosIngles])

  return (
    <div className='col-md-12'>
        <div className="position-relative">
            {tipo[0] === "MC" ?
            <ListaUCs key={listaExtra.cod_curso} listaExtra={listaExtra} />
            :
            <>
                <div className='container-fluid curso-search'>
                    <span className="material-icons">search</span>
                    <input value={query} type='search' placeholder='Pesquisar curso...' onChange={(ev) => setQuery(ev.target.value)}></input>
                </div>
                <table className='curso-lista curso'>
                    <tbody>
                        <tr>
                            <th>Código</th>
                            <th>Curso</th>
                            <th>Escola</th>
                            <th>Campus</th>
                        </tr>
                        {filteredCurso.map( (lt) => (
                        <tr key={lt.$.cod_curso}>
                            <td>{lt.$.cod_escola}<span>{lt.$.cod_curso}</span></td>
                            <td>
                                <Link to={location.pathname+"/"+urlSlug.convert(`${lt.$.nome} ${lt.$.cod_escola} ${lt.$.cod_curso}`)}>{lt.$.nome}</Link>
                                {(tipo[0] === "CI" && cursosIngles) ? 
                                cursosIngles.items.map((ci) => (
                                    <Fragment key={ci.ID}>
                                        {lt.$.cod_curso === ci.acf.cod_curso && 
                                        ci.url !== "http://x" &&
                                        <a href={ci.url} target="_blank" rel="noreferrer"> (Informação Adicional)</a>}
                                    </Fragment>
                                )):null}
                                {/* {(tipo[0] === "CL" && cursosLinguas) ? 
                                cursosLinguas.items.map((cl) => (
                                    <Fragment key={cl.ID}>
                                        {lt.$.cod_curso === cl.acf.cod_curso && 
                                        cl.url !== "http://x" &&
                                        <a href={cl.url} target="_blank" rel="noreferrer"> (Informação Adicional)</a>}
                                    </Fragment>
                                )):null} */}
                            </td>
                            <td>{escolas[lt.$.cod_escola]}</td>
                            <td>{campus[lt.$.cod_escola]}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </>}
        </div>
    </div>
  )
}

export default ListaIndividual