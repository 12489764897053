//Modules
import React from 'react'
import { useParams, Link, useLocation } from 'react-router-dom'

//Components
import Nav from '../widgets/Nav/Nav'

//Utils
import useFetch from '../utils/useFetch';

//Variables
const { REACT_APP_IPBNEWS_EP, REACT_APP_IPBNEWS_NEWS, REACT_APP_IPBNEWS_URL_IMG } = process.env

function NoticiaDetalhe() {
  let { slugNews } = useParams();
  const id = slugNews.split('-')
  let history = useLocation()

  const { data: newsItem } = useFetch(`${REACT_APP_IPBNEWS_EP}${REACT_APP_IPBNEWS_NEWS}&id=${id[id.length-1]}`)

  return (
      <>          
        {newsItem && 
          <>
            <div className="container-fluid sticky-top nav-bb" style={{backgroundColor: "white", zIndex: "9999"}}>
              <Nav dest={false} content={false} subItem={false}/>
            </div>
            <div className="container newsItem">
              <div className="newsItemWrapper">
                <Link to={(history?.state && history.state.from === "/") ? "/" : "/noticias"}><span className="material-icons-outlined newsBack">arrow_back</span></Link>
                <h3>{newsItem[0].titulo}</h3>
                <span className="newsDate"> 
                  {new Date(newsItem[0].data).toLocaleDateString("pt-PT", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                  })}
                </span>                                
                <img className="newsItemImage" src={`${REACT_APP_IPBNEWS_URL_IMG}${newsItem[0].foto}`}
                alt="NoticiaImg" />
                <p className="newsResumo">{newsItem[0].resumo}</p>
                <p className="newsTexto" dangerouslySetInnerHTML={{__html: newsItem[0].texto}}></p>
                {newsItem[0].link_video && <div dangerouslySetInnerHTML={{__html:newsItem[0].link_video}}></div>}
              </div>
            </div>
          </>} 
      </>
  )
}

export default NoticiaDetalhe
