//Modules
import { useRef } from 'react'

//Utils
import { create } from '../../utils/utilMiniUrl'


const TopMiniUrl = () => {
    const miniRef = useRef()

    const handleClickMiniUrl = () => {
        create(miniRef.current)
    }

    return (
        <>
        <div className='miniUrlButton'>
            <button type='button' ref={miniRef} onClick={handleClickMiniUrl} className="btn-top"><span className="material-symbols-outlined miniUrl">captive_portal</span></button>
        </div>
        </>
    )
}

export default TopMiniUrl