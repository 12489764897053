//Modules
import React, { useRef, useLayoutEffect, useContext } from 'react'
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom'

//Components
import AgendaItem from './AgendaItem'
import AgendaCache from './AgendaCache.js'

//Utils
import { eventsContext } from '../../utils/UtilContext'
import '../../../script/timeline.min.js'

const Agenda = () => {
    const { agendaItems, loading, isError } = useContext(eventsContext)

    const $ = window.$
    const elmt = useRef()

    let count = 0

    useLayoutEffect(() => {
        const $elmt = $(elmt.current)

        $elmt.timeline({
            mode: 'horizontal',
            visibleItems: 4,
            forceVerticalMode: 1024
          })
    })

    let agendaFilter = ((!loading || agendaItems !== null) && Array.isArray(agendaItems)) &&
        agendaItems.filter((agendaItem) => {      
            let mes = new Date(agendaItem.data_inicio).toLocaleDateString("pt-PT", {
            month: "numeric"
            })            
            let ano = new Date(agendaItem.data_inicio).getFullYear()
            if ((agendaItem.destaque === "1" && ano >= new Date().getFullYear() && Number(mes) >= new Date().getMonth()+1) || count < 4) {
            count += 1
            return agendaItem
            } else {
            return false
            }
        })

    return (
        <>        
           {((!loading || agendaItems !== null) && Array.isArray(agendaItems)) ?
            <>
                 <div className="timeline" ref={elmt}>
                    <div className="timeline__wrap">
                        <div className="timeline__items">
                            {agendaFilter.slice(0).reverse().map((agendaItem) => (
                                <AgendaItem key={agendaItem.event_id} agendaItem={agendaItem} />
                            ))}                         
                        </div>
                    </div>
                </div>
                <div className="moreEvents">
                <Link to="/eventos">Todos os Eventos</Link>
                </div>
            </> 
            : 
            <div>
                {isError || agendaItems !== null ?
                    <AgendaCache elmt={elmt} count={count} />
                :
                    <Icon style={{fontSize: '3rem'}} icon="svg-spinners:ring-resize" />
                }
            </div>
           }
        </>
    )
}

export default Agenda
