import React from 'react'
import { Link } from 'react-router-dom'

function MoreLevels({ nextLevel }) {

  return (
    <article className='moreLevels'>
        <div className="container page">    
            <div className="row">
                <div className="col-lg-3">
                </div>
                <div className="col-lg-9">
                    <div className='next-level-menu'>
                        <ul>
                            {nextLevel.map((level) => (
                                <li key={level.ID}>
                                    <p>{level.object === 'custom' ?
                                            <a href={level.url} target="_blank" rel="noreferrer"><span dangerouslySetInnerHTML={{__html:level.title}}></span></a> :
                                            <Link to={level.slug}><span dangerouslySetInnerHTML={{__html:level.title}}></span></Link>
                                        }</p>
                                    <p>{level.description}</p>
                                    <div className='buttons'>
                                        {level.object === 'custom' ?
                                            <a href={level.url} target="_blank" rel="noreferrer">+</a> :
                                            <Link to={level.slug}>+</Link>
                                        }
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>                                        
                </div>                            
            </div>
        </div>
    </article>
  )
}

export default MoreLevels