//Modules
import React, { useEffect, useState } from 'react'

const ToTop = () => {   
    const [showToTop, setShowToTop] = useState(true)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setShowToTop((window.innerHeight/4)*3 > window.scrollY)
        })

        return window.removeEventListener("scroll", () => {return})
    }, [])

    return (
        <span onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} style={{visibility: showToTop ? 'hidden' : 'visible'}} className="toTopIcon">
            <span className="material-icons-outlined">arrow_upward</span>
        </span>
    )
}

export default ToTop
