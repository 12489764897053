//Modules
import { useState, useContext, useEffect } from 'react'
import { Icon } from '@iconify/react'


//Components
import Nav from '../widgets/Nav/Nav'
import PaginatedItems from '../widgets/Page/PaginatedItems'
import FiltroData from '../widgets/FiltroData'
import EventosCache from '../widgets/Agenda/EventosCache'

//Utils
import { eventsContext } from '../utils/UtilContext'

function Eventos() {
  const { agendaItems:events, loading, isError } = useContext(eventsContext)
  const [filtroAno, setfiltroAno] = useState("0")
  const [filtroMes, setfiltroMes] = useState("0")
  const [queryPesquisa, setQueryPesquisa] = useState("")
  const anos = [];  

  ((!loading || events !== null) && Array.isArray(events)) && 
  events.map((event) => {
    let data = new Date(event.data_inicio);
    if (anos.indexOf(data.getFullYear()) === -1) {
      anos.push(data.getFullYear());
    }
    return null
  })

  useEffect(() => {
    window.scroll(0,0)
  }) 

  function handleChange(ev) {    
      let liElements = document.querySelector(".mesesList").getElementsByTagName("li")

      for (var i = 0; i < liElements.length; i++) {
        liElements[i].classList.value = ""
      }
      
      setfiltroMes(ev.target.getAttribute("id"))
      liElements[ev.target.getAttribute("id")].classList.value = "selected"
  }

  let eventsSearch = ((!loading || events !== null) && Array.isArray(events)) && 
    events.filter((eventsItem) => {
      return eventsItem.titulo.includes(queryPesquisa) || 
      (eventsItem?.categoria && eventsItem.categoria.includes(queryPesquisa)) || 
      (eventsItem?.estrutura && eventsItem.estrutura.includes(queryPesquisa))
    })

  let eventsFilterMes = ((!loading || events !== null) && Array.isArray(events)) &&
  eventsSearch.filter((eventsItem) => {      
    let mes = new Date(eventsItem.data_inicio).toLocaleDateString("pt-PT", {
      month: "numeric"
    }).toString();

    if (filtroMes !== "0"){
      return mes === filtroMes
    }else{
      return true
    }
  })

  let eventsFilter = ((!loading || events !== null) && Array.isArray(events)) &&
  eventsFilterMes.filter((eventsItem) => {      
    let ano = new Date(eventsItem.data_inicio).getFullYear().toString();

    if (filtroAno !== "0"){      
      return ano === filtroAno
    }else{
      return true
    }
  })

  return (
      <>
        <div className="container-fluid sticky-top nav-bb"  style={{backgroundColor: "white", zIndex: "9998"}}>
          <Nav dest={false} content={false} subItem={false}/>
        </div>
        <div className="container newsListWrapper">
          <div className="topBar">
            <div className="newsTitle"><span>Agenda</span></div>
            <div className="pesquisaNews">
              <input id="pesquisaNews" type="text" placeholder="Pesquisar..." onChange={(ev) => setQueryPesquisa(ev.target.value)}></input>
            </div>                
          </div>
          {((!loading || events !== null) && Array.isArray(events)) ?
              <>
                <FiltroData anos={anos} filtroAno={filtroAno} filtroMes={filtroMes} handleChange={handleChange} setfiltroAno={setfiltroAno} setfiltroMes={setfiltroMes} />
                <PaginatedItems itemsPerPage={9} items={eventsFilter} type={"eventos"}/>
              </>
            :             
            <>
              {isError || events !== null ?
                <EventosCache queryPesquisa={queryPesquisa} filtroMes={filtroMes} filtroAno={filtroAno} handleChange={handleChange} setfiltroAno={setfiltroAno}  />
              :
                <div><Icon style={{fontSize: '3rem'}} icon="svg-spinners:ring-resize" /></div>
              }
            </>} 
        </div>      
      </>
  )
}

export default Eventos
