//Modules
import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react'
import * as urlSlug from 'url-slug'

//Utils
import { newsContext } from '../../utils/UtilContext'

//Components
import NewsCache from './NewsCache'

const { REACT_APP_IPBNEWS_URL_IMG } = process.env

const News = ({ size }) => {
    const { news, loading, isError } = useContext(newsContext)

    let { pathname } = useLocation()

    return (
        <>
            {((!loading || news !== null) && Array.isArray(news)) ? 
                <>                
                    <div className="newsCardsHome">                   
                        {news.slice(0,size).map((newsItem) => (                              
                            <div key={newsItem.press_noticia_id} className="card mt-sm-3">
                                
                                <Link to={"/noticias/"+urlSlug.convert(`${newsItem.titulo} ${newsItem.press_noticia_id}`)} state={{from: pathname}}>
                                    <div className="cardImg">
                                        <img src={`${REACT_APP_IPBNEWS_URL_IMG}${newsItem.foto}`} alt="Foto"/>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-title">
                                            <p>{newsItem.titulo}</p>
                                        </div>                                
                                    </div>
                                </Link>
                                <div className="card-date">
                                    <span>
                                        {new Date(newsItem.data).toLocaleDateString("pt-PT", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        })}
                                    </span>
                                </div>
                            </div>
                        ))}                        
                    </div>                        
                    <div className="moreNews">
                        <Link to="/noticias">Todas as Notícias</Link>
                    </div>
                </>
                :                 
                <div>
                    {isError || news !== null ?
                        <NewsCache pathname={pathname} size={size} />
                    :
                        <Icon style={{fontSize: '3rem'}} icon="svg-spinners:ring-resize" />
                    }
                </div>                
            }
        </>        
    )

}

export default News