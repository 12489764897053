//Modules
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

//Utils
import { menuContext, mediaContext } from '../../utils/UtilContext'

//Assets
import navLogo from '../../../img/logo-header.png'


function NavMobile( {dest} ) {      
    const { navItemsTotal } = useContext(menuContext)
    const { media } = useContext(mediaContext)
    
    const [mobileNav, setMobileNav] = useState(null)
    const [navToggle, setNavToggle] = useState(null)
    const [navToggleImg, setNavToggleImg] = useState(null)
    const [navCurrentEl, setNavCurrentEl] = useState(null)
    const [logoHeader, setLogoHeader] = useState(navLogo)

    const abortCont = new AbortController()

    useEffect(() => {
        const logoUrl = media.filter((media) => media.slug === 'logo_header')
        logoUrl.length !== 0 && setLogoHeader(logoUrl[0].source_url)
        
        return () => abortCont.abort()
    }, [])

    useEffect(() => {
        setMobileNav(document.querySelector(".mobileNav"))
        setNavToggle(document.querySelector(".mobileNavToggle"))
        setNavToggleImg(document.querySelector(".mobileNavToggle span"))
    },[mobileNav, navToggle, navToggleImg]) 


    const toggleNav = () => {
        const visibility = mobileNav.getAttribute('data-visible')

        if (visibility === 'false') {
            mobileNav.setAttribute('data-visible', true)
            navToggle.setAttribute('aria-expanded', true)
            navToggleImg.innerHTML = 'close'
        }else if (visibility === 'true'){
            mobileNav.setAttribute('data-visible', false)
            navToggle.setAttribute('aria-expanded', false)
            navToggleImg.innerHTML = 'menu'
        }
    }

    const handleClick = (e, sub) => {
        const level = e.target.offsetParent.attributes["data-level"].value
        const el = document.querySelector("#"+sub)

        switch(level){
            case '1': 
                e.target.offsetParent.attributes["data-position"].value='left'
                el.setAttribute('data-visible', true)
                setNavCurrentEl(el)
            break
            case '2': 
                if(sub === 'back'){                    
                    e.target.offsetParent.offsetParent.attributes["data-position"].value='center'
                    navCurrentEl.setAttribute('data-visible', false)
                }else{
                    e.target.offsetParent.attributes["data-position"].value='center'
                    el.setAttribute('data-visible', true)
                    setNavCurrentEl(el)
                }
            break
            case '3': 
            if(sub === 'back'){
                e.target.offsetParent.offsetParent.attributes["data-position"].value='right'
                navCurrentEl.setAttribute('data-visible', false)
                setNavCurrentEl(e.target.offsetParent.offsetParent)                
            }
            break
            default:
                console.log("Nothing to do here!!!")
        }    
    }

    return (
        <>
        <header className='mobileHeader'>
            <div className='container'>
                <Link to='/'>
                    <img src={logoHeader} alt="Logotipo" />
                </Link>
                <ul className='mobileButtons'>
                    <li><button type='button' id='ipb-url' className="btn-top"><span className="material-symbols-outlined miniUrl">captive_portal</span></button></li>
                    <li><button data-toggle="modal" data-target="#searchModal"><span className="material-icons">search</span></button></li>
                    <li><button onClick={toggleNav} className='mobileNavToggle' aria-controls='mobileNavigation' aria-expanded='false'><span className="material-icons">menu</span></button></li>                    
                </ul> 
            </div>
        </header>
        <nav className='mobileNav' id='mobileNavigation' data-visible='false'>
            <ul data-position='center' data-visible='true' data-level='1' className='mFirstLevel'>
                {navItemsTotal.navItems.items.map((item) => (
                    <li key={item.ID}>
                        <span onClick={(e) => item?.child_items && handleClick(e,`mob_${item.slug}_${item.ID}`)}>
                            <span>{item.title}</span>{item?.child_items && <span className="material-icons-outlined">chevron_right</span>}
                        </span>
                        {item?.child_items && <ul id={`mob_${item.slug}_${item.ID}`} className="mSecondLevel" data-level='2' data-position='right' data-visible='false'>
                            <li>
                                <span onClick={(e) => handleClick(e,'back')}>
                                    <span className='mBtnBack' id='backSecondLevel'>Voltar</span><span className="material-icons-outlined">chevron_left</span>
                                </span>
                            </li>
                            <li className='mNavTitle'>
                                <div dangerouslySetInnerHTML={{__html:item.title}}></div>
                            </li>
                            {item.child_items.map((child) => (
                               <li key={child.ID}>
                                   <span onClick={(e) => child?.child_items && handleClick(e,`mob_${child.slug}_${child.ID}`)}>
                                        <span>{child.title}</span>{child?.child_items && <span className="material-icons-outlined">chevron_right</span>}
                                   </span>
                                   {child?.child_items && <ul id={`mob_${child.slug}_${child.ID}`} className="mThirdLevel" data-level='3' data-position='right' data-visible='false'>
                                        <li>
                                            <span onClick={(e) => handleClick(e,'back')}>
                                                <span className='mBtnBack' id='backThirdLevel'>Voltar</span><span className="material-icons-outlined">chevron_left</span>
                                            </span>
                                        </li>
                                        <li className='mNavTitle'>
                                            <div dangerouslySetInnerHTML={{__html:child.title}}></div>
                                        </li>
                                        {child.child_items.map((subChild) => (
                                        <li key={subChild.ID}>
                                            {subChild.object === 'custom' ?
                                                <a onClick={toggleNav} href={subChild.url} target='_blank' rel="noreferrer" id={`mob_${subChild.slug}_${subChild.ID}`}>
                                                    <div dangerouslySetInnerHTML={{__html:subChild.title}}></div>
                                                </a>:
                                                <Link onClick={toggleNav} to={`/${item.slug}/${child.slug}/${subChild.slug}`} id={`mob_${subChild.slug}_${subChild.ID}`}>
                                                    <div dangerouslySetInnerHTML={{__html:subChild.title}}></div>
                                                </Link>
                                            }
                                        </li>
                                        ))}    
                                    </ul>}
                               </li>
                            ))}    
                        </ul>}
                    </li>
                ))}
                {navItemsTotal.sobreIPB.items.map((item) => (
                    <li key={item.ID}>
                        <span onClick={(e) => item?.child_items && handleClick(e,`mob_${item.slug}_${item.ID}`)}>
                            <span>{item.title}</span>{item?.child_items && <span className="material-icons-outlined">chevron_right</span>}
                        </span>
                        {item?.child_items && <ul id={`mob_${item.slug}_${item.ID}`} className="mSecondLevel" data-level='2' data-position='right' data-visible='false'>
                            <li>
                                <span onClick={(e) => handleClick(e,'back')}>
                                    <span className='mBtnBack' id='backSecondLevel'>Voltar</span><span className="material-icons-outlined">chevron_left</span>
                                </span>
                            </li>
                            <li className='mNavTitle'>
                                <div dangerouslySetInnerHTML={{__html:item.title}}></div>
                            </li>
                            {item.child_items.map((child) => (
                               <li key={child.ID}>
                                   <span onClick={(e) => child?.child_items && handleClick(e,`mob_${child.slug}_${child.ID}`)}>
                                        <span>{child.title}</span>{child?.child_items && <span className="material-icons-outlined">chevron_right</span>}
                                   </span>
                                   {child?.child_items && <ul id={`mob_${child.slug}_${child.ID}`} className="mThirdLevel" data-level='3' data-position='right' data-visible='false'>
                                        <li>
                                            <span onClick={(e) => handleClick(e,'back')}>
                                                <span className='mBtnBack' id='backThirdLevel'>Voltar</span><span className="material-icons-outlined">chevron_left</span>
                                            </span>
                                        </li>
                                        <li className='mNavTitle'>
                                            <div dangerouslySetInnerHTML={{__html:child.title}}></div>
                                        </li>
                                        {child.child_items.map((subChild) => (
                                        <li key={subChild.ID}>
                                            {subChild.object === 'custom' ?
                                                <a onClick={toggleNav} href={subChild.url} target='_blank' rel="noreferrer" id={`mob_${subChild.slug}_${subChild.ID}`}>
                                                    <div dangerouslySetInnerHTML={{__html:subChild.title}}></div>
                                                </a>:
                                                <Link onClick={toggleNav} to={`/${item.slug}/${child.slug}/${subChild.slug}`} id={`mob_${subChild.slug}_${subChild.ID}`}>
                                                    <div dangerouslySetInnerHTML={{__html:subChild.title}}></div>
                                                </Link>
                                            }
                                        </li>
                                        ))}    
                                    </ul>}
                               </li>
                            ))}    
                        </ul>}
                    </li>
                ))}
                    <li>
                        <span onClick={(e) => navItemsTotal.menuFooter.items[0]?.child_items[0]?.child_items && handleClick(e,`mob_${navItemsTotal.menuFooter.items[0]?.child_items[0].slug}_${navItemsTotal.menuFooter.items[0]?.child_items[0].ID}`)}>
                            <span>{navItemsTotal.menuFooter.items[0]?.child_items[0].title}</span>{navItemsTotal.menuFooter.items[0]?.child_items[0]?.child_items && <span className="material-icons-outlined">chevron_right</span>}
                        </span>
                        {navItemsTotal.menuFooter.items[0]?.child_items[0]?.child_items && <ul id={`mob_${navItemsTotal.menuFooter.items[0]?.child_items[0].slug}_${navItemsTotal.menuFooter.items[0]?.child_items[0].ID}`} className="mSecondLevel" data-level='2' data-position='right' data-visible='false'>
                            <li>
                                <span onClick={(e) => handleClick(e,'back')}>
                                    <span className='mBtnBack' id='backSecondLevel'>Voltar</span><span className="material-icons-outlined">chevron_left</span>
                                </span>
                            </li>
                            <li className='mNavTitle'>
                                <div dangerouslySetInnerHTML={{__html:navItemsTotal.menuFooter.items[0]?.child_items[0].title}}></div>
                            </li>
                            {navItemsTotal.menuFooter.items[0]?.child_items[0].child_items.map((child) => (
                               <li key={child.ID}>
                                   <span onClick={(e) => child?.child_items && handleClick(e,`mob_${child.slug}_${child.ID}`)}>
                                        <span>{child.title}</span>{child?.child_items && <span className="material-icons-outlined">chevron_right</span>}
                                   </span>
                                   {child?.child_items && <ul id={`mob_${child.slug}_${child.ID}`} className="mThirdLevel" data-level='3' data-position='right' data-visible='false'>
                                        <li>
                                            <span onClick={(e) => handleClick(e,'back')}>
                                                <span className='mBtnBack' id='backThirdLevel'>Voltar</span><span className="material-icons-outlined">chevron_left</span>
                                            </span>
                                        </li>
                                        <li className='mNavTitle'>
                                            <div dangerouslySetInnerHTML={{__html:child.title}}></div>
                                        </li>
                                        {child.child_items.map((subChild) => (
                                        <li key={subChild.ID}>
                                            {subChild.object === 'custom' ?
                                                <a onClick={toggleNav} href={subChild.url} target='_blank' rel="noreferrer" id={`mob_${subChild.slug}_${subChild.ID}`}>
                                                    <div dangerouslySetInnerHTML={{__html:subChild.title}}></div>
                                                </a>:
                                                <Link onClick={toggleNav} to={`/${navItemsTotal.menuFooter.items[0]?.child_items[0].slug}/${child.slug}/${subChild.slug}`} id={`mob_${subChild.slug}_${subChild.ID}`}>
                                                    <div dangerouslySetInnerHTML={{__html:subChild.title}}></div>
                                                </Link>
                                            }
                                        </li>
                                        ))}    
                                    </ul>}
                               </li>
                            ))}    
                        </ul>}
                    </li>
                <li className='appsMobile'>
                    <div><Link to='/'><span className="material-icons">apps</span></Link></div>
                </li>
            </ul>
            {/* <ul>
                <li className='appsMobile'><Link to='/'><span className="material-icons">apps</span></Link></li>
            </ul> */}
        </nav>
        </>
    )
}

export default NavMobile
