//Components
import PaginatedItems from '../Page/PaginatedItems'
import FiltroData from '../FiltroData';

//Utils
import useFetch from "../../utils/useFetch";

//Variables
const { 
    REACT_APP_CACHE_API_ROOT,
    REACT_APP_CACHE_API_ENDPOINT,
    REACT_APP_CACHE_API_DATA_ENDPOINT,
    REACT_APP_CACHE_API_DATA_IPBNEWS_ENDPOINT,
    REACT_APP_CACHE_API_DATA_EVENTS_ENDPOINT
} = process.env

function EventosCache({ queryPesquisa, filtroMes, filtroAno, handleChange, setfiltroAno, setfiltroMes }) {
    const { data: eventsCache, loading } = useFetch(`${REACT_APP_CACHE_API_ROOT}${REACT_APP_CACHE_API_ENDPOINT}${REACT_APP_CACHE_API_DATA_ENDPOINT}${REACT_APP_CACHE_API_DATA_IPBNEWS_ENDPOINT}${REACT_APP_CACHE_API_DATA_EVENTS_ENDPOINT}`)
    console.log("Cache API in use!!!")

    const anos = [];  

    (!loading || eventsCache !== null) && 
    eventsCache.map((event) => {
        let data = new Date(event.data_inicio);
        if (anos.indexOf(data.getFullYear()) === -1) {
        anos.push(data.getFullYear());
        }
        return null
    })

    let eventsSearch = (!loading || eventsCache !== null) && 
    eventsCache.filter((eventsItem) => {
        return eventsItem.titulo.includes(queryPesquisa) || 
        (eventsItem?.categoria && eventsItem.categoria.includes(queryPesquisa)) || 
        (eventsItem?.estrutura && eventsItem.estrutura.includes(queryPesquisa))
        })

    let eventsFilterMes = (!loading || eventsCache !== null) && 
    eventsSearch.filter((eventsItem) => {      
        let mes = new Date(eventsItem.data_inicio).toLocaleDateString("pt-PT", {
        month: "numeric"
        }).toString();

        if (filtroMes !== "0"){
        return mes === filtroMes
        }else{
        return true
        }
    })

    let eventsFilter = (!loading || eventsCache !== null) &&
    eventsFilterMes.filter((eventsItem) => {      
        let ano = new Date(eventsItem.data_inicio).getFullYear().toString();

        if (filtroAno !== "0"){      
        return ano === filtroAno
        }else{
        return true
        }
    })

    return (
        <>
            {(!loading || eventsCache !== null) ? 
                <>
                    <FiltroData anos={anos} filtroAno={filtroAno} filtroMes={filtroMes} handleChange={handleChange} setfiltroAno={setfiltroAno} setfiltroMes={setfiltroMes}/>
                    <PaginatedItems itemsPerPage={9} items={eventsFilter} type={"eventos"}/>
                </>
            :null}
        </>
    )
}

export default EventosCache