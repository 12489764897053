const jq = window.$

class HashMap {
    constructor() {
        this.map = {};
    }
    get(key) {
        if (typeof this.map[key] == 'undefined')
            throw new ReferenceError("key '" + key + "' is undefined");
        return this.map[key];
    }
    size() {
        let count = 0;
        for (let _ in this.map)
            count++;
        return count;
    }
    put(key, value) {
        this.map[key] = value;
    }
    clear() {
        this.map = {};
    }
}

String.prototype.paddingLeft = function (paddingValue) {
    return String(paddingValue + this).slice(-paddingValue.length);
};

const toDateString = (date) => {
	let d = date.getDate();
    let m = date.getMonth() + 1; //Months are zero based
    let y = date.getFullYear();
    d = d < 10 ? '0' + d : d;
    m = m < 10 ? '0' + m : m;
    return y + '-' + m + '-' + d; 
};

let Combo = class {
    constructor(target, name) {
        let that = this;
        this.listeners = [];
        this.selectListeners = [];
        this.valueMap = new HashMap();

        let combo = jq('<select/>');
        target.append(combo);
        combo.attr('name', name);
        this.combo = combo;
        this.ph = '';

        combo.change(function () {
            that.fireSelect();
        });
    }
    fireSelect() {
        let val = this.combo.find(':selected');
        let that = this;
        jq.each(this.selectListeners, function (idx, l) {
            l(that.val(), val.text());
        });
    }
    notify(listener) {
        this.listeners.push(listener);
    }
    onSelect(listener) {
        this.selectListeners.push(listener);
    }
    resetInternal() {
        this.valueMap.clear();
        this.combo.empty();
        if (this.ph !== '')
            this.addItem(null, this.ph);
    }
    reset() {
        this.resetInternal();
        this.fireSelect();
    }
    status(enabled) {
        this.combo.attr('disabled', enabled ? false : 'disabled');
    }
    fill(url, keyProvider, textProvider) {
        let that = this;
        this.resetInternal();
        this.status(false);
        jq.ajax({
            url: url,
            crossDomain: true,
            success: function (json) {
                let empty = true;
                jq.each(json, function (idx, value) {
                    let text = textProvider(value);
                    let data = keyProvider(value);
                    that.addItem(data, text);
                    empty = false;
                });
                if (!empty) {
                    jq.each(that.listeners, function (idx, value) {
                        value(json);
                    });
                    that.fireSelect();
                }
                that.status(true);
            },
            error: function () {
                that.status(true);
            }
        });
    }
    addItem(data, text) {
        let idx = this.valueMap.size();
        let opt = jq('<option></option>')
            .attr('value', idx)
            .text(text);
        this.valueMap.put(idx, data);
        this.combo.append(opt);
    }
    placeholder(text) {
        this.ph = text;
        this.reset();
    }
    hide() {
        this.combo.hide();
    }
    val(value) {
        if (value) {
            this.combo.filter(function () {
                return this.attr('value') === value;
            }).prop('selected', true);
        } else {
            let idx = this.combo[0].selectedIndex;
            return this.valueMap.get(idx);
        }
    }
    addClass(clazz) {
        this.combo.addClass(clazz);
    }
};


const safeGet = (array, key, subkey, def) => {
	if(array[key])
		if(array[key][subkey])
			return array[key][subkey];
	return def;
};

export const injectHorarios = (options, lang) => {
	let id = options['id'];
	let codEscola = options['codEscola'];
	
	let target = jq('#' + id);
	target.empty();
	let baseUrl = 'https://sumarios.ipb.pt/rest';
	
	let escolaLabel = safeGet(options, 'escolaLabel', lang, 'Escola');
	let escolaPlaceholder = safeGet(options, 'escolaPlaceholder', lang, '-- Escolha a Escola --');
	let cursoLabel = safeGet(options, 'cursoLabel', lang, 'Curso');
	let cursoPlaceholder = safeGet(options, 'cursoPlaceholder', lang, '-- Escolha o Curso --');
	let semanaLabel = safeGet(options, 'semanaLabel', lang, 'Semana');
	let semanaPlaceholder = safeGet(options, 'semanaPlaceholder', lang, '-- Semana Atual --');
	let anoLabel = safeGet(options, 'anoLabel', lang, 'Ano');
	let anoPlaceholder = safeGet(options, 'anoPlaceholder', lang, '-- Todos os Anos --');
	let buttonText = safeGet(options, 'buttonText', lang, 'Ver PDF');

	let escolaDiv = jq('<div/>');
	if(options['escolaLabel'] && !codEscola)
		escolaDiv.append(
			jq('<label></label>').text(escolaLabel)
				.addClass(options['labelStyle'] ? options['labelStyle'] : '')
		);
	
	let comboEscola = new Combo( escolaDiv, 'codEscola');
	comboEscola.placeholder(escolaPlaceholder);
	if(options['escolaStyle'])
		comboEscola.addClass(options['escolaStyle']);

	target.append(escolaDiv);
	
	let cursoDiv = jq('<div/>');
	if(options['cursoLabel'])
		cursoDiv.append(
			jq('<label></label>')
				.text(cursoLabel)
				.addClass(options['labelStyle'] ? options['labelStyle'] : '')
		);
	
	let comboCurso = new Combo( cursoDiv, 'codCurso');
	comboCurso.placeholder(cursoPlaceholder);
	if(options['cursoStyle'])
		comboCurso.addClass(options['cursoStyle']);
	
	if(!codEscola)
		cursoDiv.hide();
	target.append(cursoDiv);

	let semanaDiv = jq('<div/>');
	if(options['semanaLabel'])
		semanaDiv.append(
			jq('<label></label>')
				.text(semanaLabel)
				.addClass(options['labelStyle'] ? options['labelStyle'] : '')
		);
	
	let comboSemana = new Combo( semanaDiv, 'weekDate');
	comboSemana.placeholder(semanaPlaceholder);
	if(options['semanaStyle'])
		comboSemana.addClass(options['semanaStyle']);
	
	semanaDiv.hide();
	target.append(semanaDiv);

	let anoDiv = jq('<div/>');
	if(options['anoLabel'])
		anoDiv.append(
			jq('<label></label>')
				.text(anoLabel)
				.addClass(options['labelStyle'] ? options['labelStyle'] : '')
		);
	
	let comboAno = new Combo( anoDiv, 'year');
	comboAno.placeholder(anoPlaceholder);
	if(options['anoStyle'])
		comboAno.addClass(options['anoStyle']);
	
	anoDiv.hide();
	target.append(anoDiv);
	
	// Handlers
	
	let escolaLabelProvider = function(value) {
		return value['nome'];
	};
	
	let escolaKeyProvider = function(value) {
		return value['codEscola'];
	};

	let cursoLabelProvider = function(value) {
        return value.nomeCurso + (value.nomeRamo ? ' (' + value.nomeRamo + ')'  : '') + ' [' + value.grau + ']';
	};
	
	let cursoKeyProvider = function(value) {
		return {codEscola: value.codEscola, codCurso: value.codCurso, nPlano: value.nPlano, duracao: value.duracao };
	};

	let semanaLabelProvider = function(value) {
		let d0 = new Date(value);
		let d1 = new Date(value + 6 * 24 * 60 * 60 * 1000);
		return toDateString(d0) + " ~ " + toDateString(d1);
	};
	
	let semanaKeyProvider = function(value) {
		return value;
	};

	if(!codEscola) {
		// Mostrar a combo das escolas
		comboEscola.fill(baseUrl + '/sa/escolas/list?lang=' + lang, escolaKeyProvider, escolaLabelProvider);
	} else {
		comboEscola.hide();
		comboCurso.fill(baseUrl + '/sa/cursos/list/' + codEscola + '?lang=' + lang, cursoKeyProvider, cursoLabelProvider);
	}
	
	// Events
	comboEscola.onSelect(function(value, text) {
		if(value) {
			comboCurso.fill(baseUrl + '/sa/cursos/list/' + value + '?lang=' + lang, cursoKeyProvider, cursoLabelProvider);
			cursoDiv.show();
		} else {
			comboCurso.reset();
			cursoDiv.hide();
			comboSemana.reset();
		}
	});
	
	comboCurso.onSelect(function(value, text) {
		if(value) {
			let e = value['codEscola'];
			let c = value['codCurso'];
			let p = value['nPlano'];
			comboSemana.fill(baseUrl + '/horario/semanas/plano/' + e + '/' + c + '/' + p + '?lang=' + lang, semanaKeyProvider, semanaLabelProvider);
			semanaDiv.show();
			comboAno.reset();
		} else {
			comboSemana.reset();
			semanaDiv.hide();

			if(comboEscola.val()||codEscola) {
				comboAno.reset();
				for(let i=1; i <= 3; i++)
					comboAno.addItem(i, i + " º");
				anoDiv.show();
			} else
				anoDiv.hide();
		}
	});
	
	comboSemana.onSelect(function(value, text) {
		if(value) {
			let c = comboCurso.val();
			comboAno.reset();
			for(let i=1; i <= c.duracao; i++)
				comboAno.addItem(i, i + " º");
		}
	});

	// Buttons
	
	let buttonDiv = jq('<div/>');
	buttonDiv.addClass(options['buttonDivStyle'] ? options['buttonDivStyle'] : '');
	target.append(buttonDiv);
	
	let button = jq('<button/>');
	button.text(buttonText);
	buttonDiv.append(button);
	
	button.click(function() {
		let e;
		if (!lang){
			alert('Por favor escolha um idioma.\n\nPlease choose a language.');
			return
		}
		if(!codEscola)
			e = comboEscola.val();
		else
			e = codEscola;
		if(!e) {
			lang === 'pt' ? alert('Por favor escolha a escola.') : alert('Please choose a school.'); 
			return
		}
		let urlArgs = [];
		if(comboSemana.val()) {
			let d = new Date(comboSemana.val());
			urlArgs.push('date=' + toDateString(d));
		}
		
		let y = comboAno.val();
		let year = '';
		if(y)
			year = '/' + y;
		
		let c = comboCurso.val();
		let curso = '';
		if(c)
			curso = '/' + c.codCurso + '/' + c.nPlano;
		else
			curso = '/0/0'; 
		
		urlArgs.push('lang=' + lang);

		let url = baseUrl + '/horario/get/' + e + curso + year;
		if(urlArgs.length>0)
			url = url + '?' + urlArgs.join('&');
		
		window.open(url, '', '');	
	});
	
	jq([escolaDiv, cursoDiv, semanaDiv, anoDiv, buttonDiv]).each(function(idx, obj) {
		jq(obj).addClass(options['itemStyle'] ? options['itemStyle'] : '');
	});
	
};