
const useMenuLevels = (items) => {
    const menuLevels = {
        firstLevelMenu: [],
        secondLevelMenu: [],
        thirdLevelMenu: []
    }

    items.map((item) => {
        menuLevels.firstLevelMenu.push(item)
        item?.child_items && 
        item.child_items.map((child) =>{
            menuLevels.secondLevelMenu.push(child)
            child?.child_items &&
            child.child_items.map((subChild) => {
                menuLevels.thirdLevelMenu.push(subChild)
                return null
            })
            return null
        })
        return null
    })

    return { menuLevels }
}

export default useMenuLevels
