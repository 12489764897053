import React from 'react'

function AreasCientificas({ areas }) {
  return (
    <article className='bg-color py-5'>
        <div className="container page">
            <div className="row">
                <div className="col-md-2">
                <p className='course-title-detail'><strong>Áreas Científicas que integram o plano de estudos</strong></p>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-9">
                    <div className="position-relative">
                        <table className='areas-cientificas-detail'>
                            <tbody>
                                <tr>
                                    <th><strong>Área Científica</strong></th>
                                    <th><strong>Sigla</strong></th>
                                    <th><strong>ECTS Obrigatórios</strong></th>
                                    <th><strong>ECTS Opcionais</strong></th>
                                </tr>
                                {areas.map((area, index) => (
                                    <tr key={index}>
                                        <td>{area.$.nome}</td>
                                        <td>{area.$.sigla}</td>
                                        <td>{area.$.obrigatorios}</td>
                                        <td>{area.$.opcionais}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>                                        
                </div>
            </div>
        </div>
    </article>
  )
}

export default AreasCientificas