
function ListaUCs({listaExtra}) {
    
  return (
    <table className='curso-lista'>
        <tbody>
            <tr>
                <th><strong>Código</strong></th>
                <th><strong>Unidade Curricular</strong></th>                        
                <th><strong>ECTS</strong></th>
            </tr>
            {listaExtra.map((uc) => (
                <tr key={uc.codigo}>
                    <td>{uc.codigo}</td>
                    <td><a href={uc.url} target="_blank" rel="noreferrer">{uc.disciplina}</a></td>                            
                    <td>{uc.ects}</td>
                </tr>
                ))}
        </tbody>
    </table>
  )
}

export default ListaUCs