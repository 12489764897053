//Modules
import React, { useState } from 'react'

//Components
import SearchPessoas from './SearchPessoas'
import SearchSite from './SearchSite'
import SearchNews from './SearchNews'
import SearchEvents from './SearchEvents'


function SearchForm() {
    const [filtroPesquisa, setfiltroPesquisa] = useState("")

    const makeSearch = debounce((e) => {
        setfiltroPesquisa(e.target.value)
    })

    function debounce(cb, delay=400) {
        let timeout

        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() =>{
                cb(...args)
            }, delay)
        }
    }
    
    return (
        <>
            <div className="modal fade" id="searchModal" tabIndex="-1" aria-labelledby="searchModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='inputText'>
                                <span className="material-icons">search</span>
                                <input type='text' placeholder='Pesquisa ipb.pt' onChange={(e) => makeSearch(e)}></input>
                            </div>
                            <div className='searchRes'>  
                                {filtroPesquisa !== "" && <SearchPessoas filtroPesquisa={filtroPesquisa} />}
                                <SearchSite filtroPesquisa={filtroPesquisa} />
                                <SearchNews filtroPesquisa={filtroPesquisa} />
                                <SearchEvents filtroPesquisa={filtroPesquisa} />
                            </div>
                        </div>                
                    </div>
                </div>
            </div>          
        </>
    )
}

export default SearchForm
